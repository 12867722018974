import React, { useState } from "react";
import ContactAddress from "./ContactAddress";
import { messageUS } from "../../utils/apis";
import { useToasts } from "react-toast-notifications";

function ContactForm() {
  const { addToast } = useToasts();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const { name, phone, message } = formData;
      // You can add more form fields as needed

      // Call the API function
      const response = await messageUS(name, "null", phone, "Subject", message);

      addToast("Message send successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      console.log(response);

      // Clear the form after submission if needed
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error as needed
    }
  };

  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                <h5 className="sub-title">Contact Us</h5>
                <h2 className="title">
                  Let's Talk Question<span>.</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <p>
                  Our toy store is a place where the joy and imagination of
                  children come to life. Explore a world where toys are more
                  than just playthings – they're magical companions that
                  accompany children on exciting adventures.
                </p>{" "}
                <form className="contact-form">
                  <div className="form-grp">
                    <label htmlFor="name">
                      Your Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Jon Deo..."
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="phone">
                      Phone <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="phone"
                      placeholder="+91 999 999 99 99"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="message">
                      Your Message <span>*</span>
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Opinion..."
                      value={formData.message}
                      onChange={handleChange}
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-grp checkbox-grp">
                    <input type="checkbox" id="checkbox" />
                    <label htmlFor="checkbox">
                      Don’t show your email address
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn rounded-btn"
                    onClick={handleSubmit}
                  >
                    Send Now
                  </button>
                </form>
              </div>
            </div>

            <ContactAddress />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
