import React from "react";
import Slider from "react-slick";

function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="testimonial-area testimonial-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Testimonials</h5>
              <h2 className="title">Our Happy Customers</h2>
              <p>
                Discover the most comprehensive toy selection with our Toy
                Exploration Kit (view at ToyEmporium), providing you with
                insights into various toy categories and information about the
                latest trends. Explore the joy of playtime with our extensive
                collection, suitable for kids of all ages.
              </p>
            </div>
          </div>
        </div>
        <Slider className="row testimonial-active" {...settings}>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ The most entertaining and diverse toy collection! Our Toy
                  Exploration Kit from ToyEmporium is like magic, offering a
                  wide range of toys that bring joy and fun to every child. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>ToyEnthusiast CEO</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ The most entertaining and diverse toy collection! Our Toy
                  Exploration Kit from ToyEmporium is like magic, offering a
                  wide range of toys that bring joy and fun to every child. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>ToyEnthusiast CEO</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ The most entertaining and diverse toy collection! Our Toy
                  Exploration Kit from ToyEmporium is like magic, offering a
                  wide range of toys that bring joy and fun to every child. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>ToyEnthusiast CEO</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ The most entertaining and diverse toy collection! Our Toy
                  Exploration Kit from ToyEmporium is like magic, offering a
                  wide range of toys that bring joy and fun to every child. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>ToyEnthusiast CEO</span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonial;
