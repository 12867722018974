import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";
import { Link } from "react-router-dom";

function Faq({ afterElment }) {
  useEffect(() => {
    /* magnificPopup video view */
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);

  return (
    <section className={afterElment + " faq-bg"}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-img-wrap">
              <img src="img/images/faq_tv.png" className="img-frame" alt="" />
              <img src="img/images/faq_img.png" className="main-img" alt="" />
              {/* Update the link to your toy-related video */}
              <Link
                to="https://www.youtube.com/watch?v=fTRMKsy242c"
                className="popup-video"
              ></Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title mb-35">
                <h5 className="sub-title">FAQ Question</h5>
                <h2 className="title">Discover More About Our Toys</h2>
              </div>
              <div className="accordion" id="accordionExample">
                {/* Question 1 */}
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What types of toys do you offer?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Explore a variety of toys, including educational games,
                      action figures, and more, providing endless fun for kids
                      of all ages.
                    </div>
                  </div>
                </div>
                {/* Question 2 */}
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Are there any awards for your toys?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Our toys have received recognition for their quality and
                      creativity, ensuring your child receives the best play
                      experience.
                    </div>
                  </div>
                </div>
                {/* Question 3 */}
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What age group are the toys suitable for?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Our toy collection caters to various age groups, ensuring
                      there's something delightful for every child, from
                      toddlers to pre-teens.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-shape">
        <img src="img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
}

export default Faq;
