import React from "react";
// import { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="PREV" />
    </button>
  );
}
const dummySlides = [
  {
    id: 1,
    image: "img/top-toys/slide1.webp",
    title: "Transformers",
    link: "/toylist",
  },
  {
    id: 2,
    image: "img/top-toys/slide2.webp",
    title: "Light Year",
    link: "/toylist",
  },
  {
    id: 3,
    image: "img/top-toys/slide4.webp",
    title: "Miniactures",
    link: "/toylist",
  },
  {
    id: 4,
    image: "img/top-toys/slide3.webp",
    title: "Miniactures",
    link: "/toylist",
  },
];

function BreedServices() {
  const settings = {
    nav: true,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="breeds-services pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Toys Galore</h5>
              <h2 className="title">Most Popular Toy Categories</h2>
              <p>
                Explore our diverse collection of toys, ranging from educational
                games to action figures and dolls. Find the perfect toy for your
                child's playtime joy.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Slider className="breed-services-active slick" {...settings}>
              {dummySlides.map((slide) => (
                <div key={slide.id} className="breed-services-item">
                  <div className="thumb home_slider_thumb">
                    <img src={slide.image} alt="" />
                  </div>
                  <div className="content">
                    <h3 className="title home_slider_title">
                      <Link to={slide.link}>{slide.title}</Link>
                    </h3>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="breed-services-info"
              style={{ backgroundImage: 'url("img/bg/breed_services_bg.jpg")' }}
            >
              <h5 className="sub-title">Toy Creator</h5>
              <h3 className="title">Explore Our Toy Collection</h3>
              <p>
                Discover the classic toys to the latest trends, we have
                something special for every child's enjoyment.
              </p>

              <Link to="/trending" className="btn">
                More Pets <img src="img/icon/w_pawprint.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="breed-services-shape">
        <img src="img/images/breed_services_shape01.png" alt="" />
      </div>
      <div className="breed-services-shape shape-two">
        <img src="img/images/breed_services_shape02.png" alt="" />
      </div>
    </section>
  );
}

export default BreedServices;
