import React from "react";

function AdoptionFaq() {
  return (
    <section className="faq-area faq-two-bg">
      <div className="faq-two-img" />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-7 col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title white-title mb-35">
                <h5 className="sub-title">FAQ Question</h5>
                <h2 className="title">Explore Our Toy Collection</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What types of toys do you offer?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Our toy store offers a diverse range of toys, including
                      educational toys, stuffed animals, action figures, board
                      games, and much more. We strive to cater to various
                      interests and age groups.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Are your toys safe for children?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Yes, the safety of children is our top priority. All our
                      toys comply with strict safety standards and regulations.
                      We ensure that they are free from harmful materials and
                      suitable for the specified age groups.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Can I return or exchange a toy if I'm not satisfied?{" "}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Absolutely! We understand that preferences can vary. You
                      can refer to our return policy for detailed information on
                      returning or exchanging toys within a specified period
                      after purchase.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape">
        <img src="img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
}

export default AdoptionFaq;
