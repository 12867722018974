import React from "react";
import RegisterSlider from "../components/register/RegisterSlider";
import RegisterGallery from "../components/register/AdoptionGallery";

function Register() {
  return (
    <main>
      <RegisterSlider />
      <RegisterGallery />
    </main>
  );
}

export default Register;
