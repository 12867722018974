import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import {
  selectCartItems,
  selectCartTotal,
  removeItem,
} from "../feature/cartSlice";
import { useSelector, useDispatch } from "react-redux";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState();

  const cartItems = useSelector(selectCartItems);

  const total = useSelector(selectCartTotal);
  const itemCount = cartItems.length;

  const handleActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.classList += " active";
  };
  const subActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.parentNode.parentNode.classList += " active";
  };
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }

    $(".navbar-toggle").on("click", function () {
      $(".navbar-nav").addClass("mobile_menu");
    });
    $(".navbar-nav li a").on("click", function () {
      $(".navbar-collapse").removeClass("show");
    });

    $(".header-search > a").on("click", function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });

    $(".search-close").on("click", function () {
      $(".search-popup-wrap").slideUp(500);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    setUser(user ? JSON.parse(user) : null);
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    history.push("/login");
  };

  useEffect(() => {
    const handleRouteChange = () => {
      // This will be called whenever the route changes
      const token = localStorage.getItem("token");
      const user = localStorage.getItem("user");

      setUser(user ? JSON.parse(user) : null);
      setIsLoggedIn(!!token);
    };

    // Add a listener for route changes
    const unlisten = history.listen(handleRouteChange);

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history, setUser, setIsLoggedIn]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const removeCart = async (id) => {
    dispatch(removeItem(id));
  };

  return (
    <header>
      <div className="header-top-area">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="header-top-left">
                <ul>
                  <li>Call us: 999 999 99 99</li>
                  <li>
                    <i className="far fa-clock" />
                    Opening Hours: 7:00 am - 9:00 pm (Mon - Sun)
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="header-top-right">
                <ul className="header-top-social">
                  <li className="follow">Follow :</li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo">
                    <Link to="/">
                      <img src="img/logo/logo.webp" alt="" />
                    </Link>
                  </div>

                  {windowWidth <= 768 ? (
                    <ul className="header-buttons" style={{ display: "flex" }}>
                      <li
                        className="header-shop-cart"
                        style={{ marginTop: "10px", marginRight: "10px" }}
                      >
                        <a>
                          <i className="flaticon-shopping-bag" />
                          <span style={{ color: "red" }}></span>
                        </a>
                        <ul className="minicart">
                          {cartItems?.map((item) => (
                            <li
                              key={item?.id}
                              className="d-flex align-items-start"
                            >
                              <div className="cart-img">
                                <a href="/#">
                                  <img src={item?.image} alt={item?.name} />
                                </a>
                              </div>
                              <div className="cart-content">
                                <h4>
                                  <a>{item?.name}</a>
                                </h4>
                                <div className="cart-price">
                                  <span className="new">
                                    ₹{item?.price} x {item.quantity}
                                  </span>
                                </div>
                              </div>
                              <div className="del-icon">
                                <a onClick={() => removeCart(item?.id)}>
                                  <i className="far fa-trash-alt" />
                                </a>
                              </div>
                            </li>
                          ))}
                          <li>
                            <div className="total-price">
                              <span className="f-left">Total:</span>
                              <span className="f-right">₹{total}</span>
                            </div>
                          </li>
                          <li>
                            <div className="checkout-link">
                              <Link to="/cart">Shopping Cart</Link>
                              <Link to="/checkout">Checkout</Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                      {isLoggedIn ? (
                        // Render user avatar with dropdown if the user is logged in
                        <li className="header-avatar">
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="userDropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{
                                marginRight: "20px",
                                padding: "4px 6px 6px 13px",
                              }}
                            >
                              {/* Display user avatar or initials */}
                              {user.avatar ? (
                                <img
                                  src={user.avatar}
                                  alt="User Avatar"
                                  className="avatar-img"
                                />
                              ) : (
                                <span className="avatar-initials">
                                  {user.name.charAt(0)}
                                </span>
                              )}
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="userDropdown"
                            >
                              {/* Dropdown content, e.g., profile, logout options */}
                              <Link className="dropdown-item" to="/my-orders">
                                My Orders
                              </Link>
                              <div className="dropdown-divider"></div>
                              <button
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Logout
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : (
                        // Render "Sign Up" button if the user is not logged in
                        <li className="header-btn">
                          <Link
                            to="/register"
                            className="btn"
                            style={{
                              marginRight: "20px",
                              padding: "6px 6px 6px 13px",
                            }}
                          >
                            Sign Up <img src="img/icon/w_pawprint.png" alt="" />
                          </Link>
                        </li>
                      )}
                    </ul>
                  ) : null}

                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      {/* <li className="active menu-item-has-children"><Link to="/">Home</Link>
                          <ul className="submenu">
                            <li className="active" onClick={(e)=> subActive(e)}><Link to="/">Home One</Link></li>
                            <li><Link to="/home-two" onClick={(e)=> subActive(e)}>Home Two</Link></li>
                          </ul>
                        </li> */}
                      <li>
                        <Link to="/" onClick={(e) => handleActive(e)}>
                          Home
                        </Link>
                      </li>

                      <li>
                        <Link to="/toylist" onClick={(e) => handleActive(e)}>
                          Toy List
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/shop" onClick={(e) => handleActive(e)}>
                          Shop
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/shop" onClick={(e) => subActive(e)}>
                              Our Shop
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/trending" onClick={(e) => handleActive(e)}>
                          Trending
                        </Link>
                      </li>
                      {/* <li className="menu-item-has-children">
                        <Link to="/breeder" onClick={(e) => handleActive(e)}>
                          Gifts
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/breeder" onClick={(e) => subActive(e)}>
                              Our Breeder
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/breeder-details"
                              onClick={(e) => subActive(e)}
                            >
                              Breeder Details
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="menu-item-has-children">
                        <Link to="/blogs" onClick={(e) => handleActive(e)}>
                          Blog
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/blogs" onClick={(e) => subActive(e)}>
                              Our Blog
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/blog-details"
                              onClick={(e) => subActive(e)}
                            >
                              Blog Details
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <Link to="/contacts" onClick={(e) => handleActive(e)}>
                          contacts
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-search">
                        <a href="/#">
                          <i className="flaticon-search" />
                        </a>
                      </li>
                      <li className="header-shop-cart">
                        <a>
                          <i className="flaticon-shopping-bag" />
                          <span style={{ color: "red" }}>{itemCount || 0}</span>
                        </a>
                        <ul className="minicart">
                          {cartItems?.map((item) => (
                            <li
                              key={item?.id}
                              className="d-flex align-items-start"
                            >
                              <div className="cart-img">
                                <a href="/#">
                                  <img src={item?.image} alt={item?.name} />
                                </a>
                              </div>
                              <div className="cart-content">
                                <h4>
                                  <a>{item?.name}</a>
                                </h4>
                                <div className="cart-price">
                                  <span className="new">
                                    ₹{item?.price} x {item.quantity}
                                  </span>
                                </div>
                              </div>
                              <div className="del-icon">
                                <a onClick={() => removeCart(item?.id)}>
                                  <i className="far fa-trash-alt" />
                                </a>
                              </div>
                            </li>
                          ))}
                          <li>
                            <div className="total-price">
                              <span className="f-left">Total:</span>
                              <span className="f-right">₹{total}</span>
                            </div>
                          </li>
                          <li>
                            <div className="checkout-link">
                              <Link to="/cart">Shopping Cart</Link>
                              <Link to="/checkout" className="black-color">Checkout</Link>
                            </div>
                          </li>
                        </ul>
                      </li>

                      {isLoggedIn ? (
                        // Render user avatar with dropdown if the user is logged in
                        <li className="header-avatar">
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="userDropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {/* Display user avatar or initials */}
                              {user.avatar ? (
                                <img
                                  src={user.avatar}
                                  alt="User Avatar"
                                  className="avatar-img"
                                />
                              ) : (
                                <span className="avatar-initials">
                                  {user.name.charAt(0)}
                                </span>
                              )}
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="userDropdown"
                            >
                              {/* Dropdown content, e.g., profile, logout options */}
                              <Link className="dropdown-item" to="/my-orders">
                                My Orders
                              </Link>
                              <div className="dropdown-divider"></div>
                              <button
                                className="dropdown-item"
                                onClick={handleLogout}
                              >
                                Logout
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : (
                        // Render "Sign Up" button if the user is not logged in
                        <li className="header-btn">
                          <Link to="/register" className="btn">
                            Sign Up <img src="img/icon/w_pawprint.png" alt="" />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src="img/logo/logo.webp" alt="" title="true" />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="/#">
                          <span className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-facebook-square" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
        <div
          className="header-shape"
          style={{ backgroundImage: "url('img/bg/header_shape.png')" }}
        />
      </div>

      <div
        className="search-popup-wrap"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="search-close">
          <span>
            <i className="fas fa-times" />
          </span>
        </div>
        <div className="search-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">... Search Here ...</h2>
                <div className="search-form">
                  <form>
                    <input
                      type="text"
                      name="search"
                      placeholder="Type keywords here"
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
