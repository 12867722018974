import React from "react";
import ShopBreadcumb from "../components/ourshop/ShopBreadcumb";
import ShopArea from "../components/ourshop/ShopArea";
import OrdersBreadcumb from "../components/Orders/OrdersBreadCrumb";
import OrdersTable from "../components/Orders";

function MyOrders() {
  return (
    <main>
      <OrdersBreadcumb />
      <OrdersTable />
    </main>
  );
}
export default MyOrders;
