import React from "react";
import RegisterGallery from "../components/register/AdoptionGallery";
import LoginSlider from "../components/login/LoginSlider";

function Login() {
  return (
    <main>
      <LoginSlider />
      <RegisterGallery />
    </main>
  );
}

export default Login;
