import React from "react";
import Slider from "react-slick";

function RegisterGallery() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="breeder-gallery-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <Slider className="breeder-gallery-active" {...settings}>
              <div className="breeder-gallery-item">
                <img src="img/images/reg1.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg2.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg3.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg4.webp" alt="" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-6">
            <div className="adoption-content">
              <h5 className="sub-title">Explore Toys</h5>
              <h2 className="title">
                Exciting Toys <span>for Fun Time</span>
              </h2>
              <p>
                Discover the best toys for your furry friend! Whether it's
                engaging squeaky toys or interactive playthings, we have a wide
                selection to keep your pet entertained. View our top-quality
                toys at Chewy and make playtime a joyful experience for your
                pet.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> Interactive Squeaky Toys
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Durable Chew Toys
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Plush and Soft Toys
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Engaging Puzzle Toys
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterGallery;
