import React from "react";
import Slider from "react-slick";

function AdoptionGallery() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="breeder-gallery-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <Slider className="breeder-gallery-active" {...settings}>
              <div className="breeder-gallery-item">
                <img src="img/images/reg1.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg2.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg3.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg4.webp" alt="" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-6">
            <div className="adoption-content">
              <h5 className="sub-title">Explore Fun</h5>
              <h2 className="title">
                Discover a World of <span>Playfulness</span> <br /> Happy
                Moments
              </h2>
              <p>
                Dive into a world of fun and excitement with our wide range of
                toys, specially curated for your furry friends. Our toys are
                designed to provide endless joy and entertainment, ensuring that
                your pets have a happy and playful time.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> Interactive Toy Collection
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Specially Curated Playthings
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptionGallery;
