import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import $ from 'jquery'
import { useParams } from "react-router-dom";
import { productByID } from "../../utils/apis";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  selectCartItems,
  selectCartTotal,
} from "../../feature/cartSlice";
import { useToasts } from "react-toast-notifications";

function ShopDetailsArea() {
  const { id } = useParams();
  const { addToast } = useToasts();

  const { data } = useQuery({
    queryKey: ["productsById"],
    queryFn: () => productByID(id),
  });

  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const [quantity, setQuantity] = React.useState(1);
  const [total, setTotal] = useState();
  const [activeColor, setActiveColor] = useState(null);

  const increase = () => {
    setQuantity(quantity + 1);
  };

  const decrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const colorActive = (color) => {
    setActiveColor(color === activeColor ? null : color);
  };

  useEffect(() => {
    // Scroll to an element with an ID of 'someElement' on component mount
    const element = document.getElementById("someElement");
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  const handleAddToCart = () => {
    const newItem = {
      id: data?.id,
      name: data?.name,
      price: data?.price,
      image: data?.image,
      quantity,
      total,
      activeColor: activeColor === null ? data?.colors[0] : activeColor
    };
    dispatch(addItem(newItem));

    addToast("Added to Cart!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    if (data) {
      setTotal(data.price * quantity);
    }
  }, [data, quantity]);

  return (
    <section className="shop-details-area pt-110 pb-50" id="someElement">
      <div className="container">
        <div className="shop-details-wrap">
          <div className="row">
            <div className="col-7">
              <div className="shop-details-img-wrap">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane show active"
                    id="item-one"
                    role="tabpanel"
                    aria-labelledby="item-one-tab"
                  >
                    <div className="shop-details-img">
                      <img src={data?.product_image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="shop-details-content">
                <span>{data?.category?.name}</span>
                <h2 className="title">{data?.name}</h2>
                <div className="shop-details-review">
                  <div className="rating">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  {/* <span>( 01 Review )</span> */}
                </div>
                <div className="shop-details-price">
                  <h2 className="price">₹{total}</h2>
                  <h5 className="stock-status">- IN Stock</h5>
                </div>
                <p>{data?.description}</p>

                <div className="shop-details-color">
                  <span>Color :</span>
                  <ul>
                    {data?.colors.map((color, index) => (
                      <li
                        key={index}
                        style={{ backgroundColor: color }}
                        className={activeColor === color ? "active" : ""}
                        onClick={() => colorActive(color)}
                      />
                    ))}
                  </ul>
                </div>
                <div className="shop-details-quantity">
                  <span>Quantity :</span>
                  <div className="cart-plus-minus">
                    <input type="text" value={quantity} readOnly />
                    <div className="dec qtybutton" onClick={() => decrease()}>
                      -
                    </div>
                    <div className="inc qtybutton" onClick={() => increase()}>
                      +
                    </div>
                  </div>
                  {/* <Link to="/shop-details" className="wishlist-btn">
                    <i className="fas fa-heart" /> Add to Wishlist
                  </Link> */}
                  <a
                    style={{ cursor: "pointer" }}
                    className="cart-btn"
                    onClick={handleAddToCart}
                  >
                    Add to Cart +
                  </a>
                </div>
                <div className="shop-details-bottom">
                  <ul>
                    <li className="sd-category">
                      <span className="title">Categories :</span>
                      <Link to="#">{data?.category?.name}</Link>
                    </li>
                    <li className="sd-sku">
                      <span className="title">Brand :</span>
                      <Link to="#">{data?.brand.name}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="product-desc-wrap">
              <ul className="nav nav-tabs" id="myTabTwo" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="details-tab"
                    data-toggle="tab"
                    href="/#details"
                    role="tab"
                    aria-controls="details"
                    aria-selected="true"
                  >
                    Details More
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContentTwo">
                <div
                  className="tab-pane fade show active"
                  id="details"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                >
                  <div className="product-desc-content">
                    <p>{data?.description}</p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="val"
                  role="tabpanel"
                  aria-labelledby="val-tab"
                >
                  <div className="product-desc-info">
                    <div className="row">
                      <div className="col-xl-3 col-md-5">
                        <div className="product-desc-img">
                          <img src="img/product/desc_img.jpg" alt="" />
                        </div>
                      </div>
                      <div className="col-xl-9 col-md-7">
                        <h5 className="small-title">100% Knit Knacks</h5>
                        <p>
                          Cramond Leopard &amp; Pythong Print Anorak Jacket In
                          Beige but also the leap into electronic typesetting,
                          remaining lorem Ipsum is simply dummy text of the
                          printing and typesetting industry. Lorem ipsum has
                          been the industry's standard dummy text ever since the
                          1500s, when an unknown printer took a galley of type
                          and scrambled it to make a type specimen book.
                        </p>
                        <ul className="product-desc-list">
                          <li>65% poly, 35% rayon</li>
                          <li>Partially lined</li>
                          <li>
                            Hidden front button closure with keyhole accents
                          </li>
                          <li>Button cuff sleeves</li>
                          <li>Lightweight semi-sheer fabrication</li>
                          <li>Made in USA</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="review"
                  role="tabpanel"
                  aria-labelledby="review-tab"
                >
                  <div className="product-desc-review">
                    <div className="review-title mb-20">
                      <h4 className="title">Customer Reviews (0)</h4>
                    </div>
                    <div className="left-rc">
                      <p>No reviews yet</p>
                    </div>
                    <div className="right-rc">
                      <a href="/#">Write a review</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="related-products-wrap">
          <h2 className="title">Related Products</h2>
          <Slider className="row related-product-active" {...settings}>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/shop-details">
                    <img src="img/product/shop_item01.jpg" alt="" />
                  </Link>
                </div>
                <div className="shop-content">
                  <span>Dog toy’s</span>
                  <h4 className="title">
                    <Link to="/shop-details">Pet Knit Knacks</Link>
                  </h4>
                  <div className="shop-content-bottom">
                    <span className="price">$28.00</span>
                    <span className="add-cart">
                      <Link to="/shop-details">ADD +</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/shop-details">
                    <img src="img/product/shop_item02.jpg" alt="" />
                  </Link>
                </div>
                <div className="shop-content">
                  <span>Dog toy’s</span>
                  <h4 className="title">
                    <Link to="/shop-details">Squeaky Dog</Link>
                  </h4>
                  <div className="shop-content-bottom">
                    <span className="price">$19.00</span>
                    <span className="add-cart">
                      <Link to="/shop-details">ADD +</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/shop-details">
                    <img src="img/product/shop_item03.jpg" alt="" />
                  </Link>
                </div>
                <div className="shop-content">
                  <span>Dog toy’s</span>
                  <h4 className="title">
                    <Link to="/shop-details">Pet Knit Knacks</Link>
                  </h4>
                  <div className="shop-content-bottom">
                    <span className="price">$29.00</span>
                    <span className="add-cart">
                      <Link to="/shop-details">ADD +</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/shop-details">
                    <img src="img/product/shop_item04.jpg" alt="" />
                  </Link>
                </div>
                <div className="shop-content">
                  <span>Dog toy’s</span>
                  <h4 className="title">
                    <Link to="/shop-details">Yoda Carriage</Link>
                  </h4>
                  <div className="shop-content-bottom">
                    <span className="price">$49.00</span>
                    <span className="add-cart">
                      <Link to="/shop-details">ADD +</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="shop-item mb-55">
                <div className="shop-thumb">
                  <Link to="/shop-details">
                    <img src="img/product/shop_item05.jpg" alt="" />
                  </Link>
                </div>
                <div className="shop-content">
                  <span>Dog toy’s</span>
                  <h4 className="title">
                    <Link to="/shop-details">Pet Carriage</Link>
                  </h4>
                  <div className="shop-content-bottom">
                    <span className="price">$09.00</span>
                    <span className="add-cart">
                      <Link to="/shop-details">ADD +</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div> */}
      </div>
    </section>
  );
}
export default ShopDetailsArea;
