import React from 'react';
import ShopDetailsArea from '../components/shopdetails/ShopDetailsArea'
import CheckoutComp from '../components/checkout';

function Checkout() {
  return (
	  <main>
	  {/* <ShopDetailsBreadcumb/> */}
	  <CheckoutComp/>
	  </main>
  )
}
export default Checkout;
