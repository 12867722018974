import { createSlice } from "@reduxjs/toolkit";

const loadCartFromStorage = () => {
  const storedCart = localStorage.getItem("cart");
  return storedCart ? JSON.parse(storedCart) : { items: [], total: 0 };
};

const findItemIndexById = (items, itemId) => {
  return items.findIndex((item) => item.id === itemId);
};

const initialState = loadCartFromStorage();

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = findItemIndexById(state.items, newItem.id);

      if (existingItemIndex !== -1) {
        // If the item already exists, update its quantity, total, color, and image
        const existingItem = state.items[existingItemIndex];
        existingItem.quantity = newItem.quantity;
        existingItem.total = newItem.total;
        existingItem.activeColor = newItem.activeColor;
        existingItem.image = newItem.image;

        
      } else {
        // If the item doesn't exist, add it to the cart
        state.items.push(newItem);
        
      }

      // Recalculate the total for the entire cart
      state.total = state.items.reduce((total, item) => total + item.total, 0);

      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeItem: (state, action) => {
      const itemIdToRemove = action.payload;
      const itemToRemove = state.items.find(
        (item) => item.id === itemIdToRemove
      );
      if (itemToRemove) {
        state.total -= itemToRemove.price * itemToRemove.quantity;
        state.items = state.items.filter((item) => item.id !== itemIdToRemove);
        localStorage.setItem("cart", JSON.stringify(state));
      }
    },
    updateQuantity: (state, action) => {
      const { itemId, newQuantity } = action.payload;
      const itemIndex = findItemIndexById(state.items, itemId);

      if (itemIndex !== -1) {
        state.items[itemIndex].quantity = newQuantity;
        state.items[itemIndex].total =
          newQuantity * state.items[itemIndex].price;
        state.total = state.items.reduce(
          (total, item) => total + item.total,
          0
        );
        localStorage.setItem("cart", JSON.stringify(state));
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.total = 0;
      localStorage.removeItem("cart");
    },
  },
});

export const { addItem, removeItem, updateQuantity, clearCart } =
  cartSlice.actions;
export const selectCartItems = (state) => state.cart.items;
export const selectCartTotal = (state) => state.cart.total;

export default cartSlice.reducer;
