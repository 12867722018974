import React, { useState, useEffect } from "react";
import { myOrder } from "../../utils/apis";

function OrdersTable() {
  const [orders, setOrders] = useState([]);

  const my_order = async () => {
    try {
      const user = localStorage.getItem("user");
      const user_id = JSON.parse(user);
      const data = await myOrder(user_id.id);
      setOrders(data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    my_order();
  }, []);

  return (
    <div>
      <div
        className="container"
        style={{ marginBottom: "200px", marginTop: "100px" }}
      >
        <h2>My Orders</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Order ID</th>
              <th scope="col">Product Name</th>
              <th scope="col">Order Status</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order, index) => (
              <tr key={index + 1}>
                <th scope="row">{index + 1}</th>
                <td>{order.name}</td>
                <td>{order.order_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrdersTable;
