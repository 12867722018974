import React from 'react';
import BlogPost from '../components/blogdetails/BlogPost'
import BlogDetBread from '../components/blogdetails/BlogDetBread'

function BlogDetailsPage() {
  
  return (
    
        <main>
        
         <BlogDetBread/> 
          <BlogPost/>
        </main>

  )
}

export default BlogDetailsPage;
