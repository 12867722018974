import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { fetchProductData } from "../utils/apis";
import { useQuery } from "@tanstack/react-query";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="PREV" />
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="NEXT" />
    </button>
  );
}

function AdoptionShop() {
  const settings = {
    nav: true,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProductData,
  });

  const renderLoader = () => (
    <div className="text-center">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );

  const renderError = () => <span>Error: {error?.message}</span>;

  const calculateColumnClass = (data) => {
    switch (data) {
      case 1:
        return "col-lg-12";
      case 2:
        return "col-lg-6";
      default:
        return "col-lg-4";
    }
  };

  if (!isMobile) {
    return (
      <section className="adoption-shop-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon">
                  <img src="img/icon/toy_icon.png" alt="" />
                </div>
                <h5 className="sub-title">Explore the Collection</h5>
                <h2 className="title">Toys Awaiting Playtime</h2>
                <p>
                  Discover the joy of playtime with our diverse collection of
                  toys. From educational games to fun action figures, we have
                  something special for every child's delight.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {isPending && renderLoader()}
            {isError ? renderError() : null}
            {!isPending && !isError && data && (
              <div className="row">
                {data?.map((item) => (
                  <div
                    key={item.id}
                    className={calculateColumnClass(data.length)}
                  >
                    <Link to={`/shop-details/${item.id}`} style={{color:"black"}}>
                      <div className="adoption-shop-item">
                        <div className="adoption-shop-thumb">
                          <img src={item.image} alt="" />
                          <Link to={`/shop-details/${item.id}`} className="btn">
                            Buy Now <img src="img/icon/w_pawprint.png" alt="" />
                          </Link>
                        </div>
                        <div className="adoption-shop-content">
                          <h4 className="title">
                            <Link to={`/shop-details/${item.id}`}>
                              {item.name}
                            </Link>
                          </h4>
                          <div className="adoption-meta">
                            <ul>
                              <li>
                                <i className="fas fa-cog" />
                                <a href="/#">{item.category}</a>
                              </li>
                              <li>
                                <i className="far fa-calendar-alt" /> Release
                                Year : {item.releaseYear}
                              </li>
                            </ul>
                          </div>
                          <div className="adoption-rating">
                            <ul>
                              <li className="rating">
                                {Array.from(
                                  { length: item.rating },
                                  (_, index) => (
                                    <i key={index} className="fas fa-star" />
                                  )
                                )}
                              </li>
                              <li className="price">
                                Total Price : <span>{item.price}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}

            <></>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="img/icon/toy_icon.png" alt="" />
              </div>
              <h5 className="sub-title">Explore the Collection</h5>
              <h2 className="title">Toys Awaiting Playtime</h2>
              <p>
                Discover the joy of playtime with our diverse collection of
                toys. From educational games to fun action figures, we have
                something special for every child's delight.
              </p>
            </div>
          </div>
        </div>
        <Slider className="breed-services-active slick" {...settings}>
          {data?.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-6">
              <div className="adoption-shop-item">
                <div className="adoption-shop-thumb">
                  <img src={item.image} alt="" />
                  <Link to={`/shop-details/${item.id}`} className="btn">
                    Buy Now <img src="img/icon/w_pawprint.png" alt="" />
                  </Link>
                </div>
                <div className="adoption-shop-content">
                  <h4 className="title">
                    <Link to={`/shop-details/${item.id}`}>{item.name}</Link>
                  </h4>
                  <div className="adoption-meta">
                    <ul>
                      <li className="rating">
                        {Array.from({ length: item.rating }, (_, index) => (
                          <i
                            key={index}
                            className="fas fa-star"
                            style={{ color: "gold" }}
                          />
                        ))}
                      </li>
                    </ul>
                  </div>

                  <div className="adoption-rating">
                    <ul>
                      <li className="price">
                        Price : <span>{item.price}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default AdoptionShop;
