import React from 'react';
import { Link } from 'react-router-dom';

function Find() {
  return (
    <div className="find-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <form>
              <div className="find-wrap">
                <div className="location">
                  <i className="flaticon-location" />
                  <input type="text" defaultValue="Enter City, State, or Zip" />
                </div>
                <div className="find-category">
                  <ul>
                    <li><Link to="/toylist"><i className="flaticon-toy" /> Toys for Kids</Link></li>
                    <li><Link to="/toylist"><i className="flaticon-game-controller" /> Board Games</Link></li>
                    <li><Link to="/toylist"><i className="flaticon-doll" /> Dolls and Action Figures</Link></li>
                  </ul>
                </div>
                <div className="other-find">
                  <div className="dropdown">
                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      More Toy Options
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link className="dropdown-item" to="/toylist">Educational Toys</Link>
                      <Link className="dropdown-item" to="/toylist">Outdoor Play</Link>
                      <Link className="dropdown-item" to="/toylist">Puzzles</Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Find;
