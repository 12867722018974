import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function AdoptionSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="inner-breeder-area breeder-bg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
            <div className="adoption-content">
              <h5 className="sub-title">Explore Toys</h5>
              <h2 className="title">
                Joyful Moments with <span>Interactive Toys</span>
              </h2>
              <p>
                Discover the best toys for your furry friend at Chewy! From
                chewable toys to engaging playthings, we have everything to make
                playtime a happy time for your beloved companions.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> Interactive Chew Toys
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Squeaky Plush Toys
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Puzzle and Brain Games
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Durable and Safe Materials
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
            <div className="breeder-info-wrap">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-6">
                  <Slider className="breeder-active owl-carousel" {...settings}>
                    <div className="breeder-item">
                      <img src="img/trending/slide1.webp" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="img/trending/slide2.webp" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="img/trending/slide3.webp" alt="" />
                    </div>
                  </Slider>
                </div>
                <div className="col-md-6">
                  <div
                    className="breed-services-info"
                    style={{
                      backgroundImage: 'url("img/bg/breed_services_bg.jpg")',
                    }}
                  >
                    <h5 className="sub-title">Toy Adoption</h5>
                    <h2 className="title">Perfect Toys</h2>
                    <p>
                      our carefully curated selection guarantees hours of
                      entertainment for your furry friends.
                    </p>
                    <Link to="/toylist" className="btn">
                      More Pets <img src="img/icon/w_pawprint.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default AdoptionSlider;
