import React from 'react'
import {Link} from 'react-router-dom'

function Adoption() {


  
  return (
    <section className="adoption-area">
    <div className="container">
      <div className="row align-items-center align-items-xl-end justify-content-center">
        <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
          <div className="adoption-img">
            <img src="img/images/homerezise.webp" alt="" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6">
          <div className="adoption-content">
          <h2 className="title">Explore <br /> Fun <span>Times</span> with Toys</h2>
          <p>Discover a world of joy and imagination with our diverse collection of toys for kids of all ages.</p>
              <Link to="/toylist" className="btn">Explore Toys <img src="img/icon/toy_icon.png" alt="" /></Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Adoption;
