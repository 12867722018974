import React from "react";
import CartBreadcumb from "../components/cart/CartBreadcumb";
import CartPage from "../components/cart";
import RegisterGallery from "../components/register/AdoptionGallery";

function Cart() {

  return (
    <main>
        <CartBreadcumb/>
        <CartPage/>
        <RegisterGallery />
    </main>
  );
}

export default Cart;
