import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCartItems,
  selectCartTotal,
  removeItem,
  updateQuantity,
} from "../../feature/cartSlice";

function CartPage() {
  const dispatch = useDispatch();

  const cartItems = useSelector(selectCartItems);
  const subTotal = useSelector(selectCartTotal);
  const shipping = 20; // Shipping fee is ₹20
  const totalPrice = subTotal;

  const handleUpdateQuantity = (itemId, newQuantity) => {
    // Ensure the new quantity is at least 1
    newQuantity = Math.max(1, newQuantity);
    dispatch(updateQuantity({ itemId, newQuantity }));
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeItem(itemId));
  };

  return (
    <div className="cart-area py-3 rpy-1">
      <div className="container">
        <div className="cart-item-wrap mb-3 wow fadeInUp delay-0-2s">
          <div className="table-responsive">
            {cartItems.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Variant</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((cart, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={cart.image}
                          style={{
                            width: "100px",
                            height: "70px",
                            objectFit: "contain",
                          }}
                        ></img>
                      </td>
                      <td>
                        <div className="cart-item d-flex align-items-center">
                          <div className="cart-item-details ml-2">
                            <h5 className="cart-item-name">{cart.name}</h5>
                          </div>
                        </div>
                      </td>
                      <td>₹{Number(cart.price)}</td>
                      <td>
                        <div className="quantity-input btn-group">
                          <button
                            style={{ width: "55px", height: "35px" }}
                            className="btn btn-outline-secondary flex-fill"
                            onClick={() =>
                              handleUpdateQuantity(cart.id, cart.quantity - 1)
                            }
                          >
                            <span aria-hidden="true">-</span>
                          </button>
                          <input
                            className=" text-center flex-fill"
                            style={{ width: "55px", height: "35px" }}
                            type="text"
                            value={cart.quantity}
                            readOnly
                          />
                          <button
                            style={{ width: "55px", height: "35px" }}
                            className="btn btn-outline-secondary flex-fill"
                            onClick={() =>
                              handleUpdateQuantity(cart.id, cart.quantity + 1)
                            }
                          >
                            <span aria-hidden="true">+</span>
                          </button>
                        </div>
                      </td>
                      <td>
                        <p
                          style={{ backgroundColor: cart.activeColor, width:"25px" }}
                          // className={activeColor === color ? "active" : ""}
                        >
                          .
                        </p>
                      </td>
                      <td>₹{cart.quantity * cart.price}</td>{" "}
                      <td>
                        <button
                          style={{
                            padding: "0.25rem 0.5rem",
                            fontSize: "0.875rem",
                            lineHeight: 1.5,
                            borderRadius: "0.2rem",
                          }}
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleRemoveItem(cart.id)}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <center>Your cart is empty.</center>
            )}
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="payment-cart-total pt-2 wow fadeInUp delay-0-2s">
          {cartItems.length > 0 && (
            <div className="row justify-content-end">
              <div className="col-lg-5">
                <div className="shoping-cart-total mt-3">
                  <h4 className="form-title m-2">Cart Totals</h4>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Cart Subtotal</td>
                        <td className="sub-total-price">₹{subTotal}</td>
                      </tr>
                      {/* <tr>
                        <td>Shipping Fee</td>
                        <td className="shipping-price">
                          ₹{shipping.toFixed(2)}
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <strong>Order Total</strong>
                        </td>
                        <td>
                          <strong className="total-price">₹{totalPrice}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Link to="/checkout" className="btn w-100">
                    Proceed to checkout
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartPage;
