import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img src="img/logo/logo.webp" alt="" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>
                    Discover the best overall toy collection with our Toy DNA
                    Kit. The Embark Toy Breed &amp; Play Kit (view at Chewy)
                    provides you with a comprehensive collection of toys for
                    every playtime moment.
                  </p>
                </div>
                <div className="footer-contact">
                  <div className="icon">
                    <i className="fas fa-headphones" />
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="tel:0987654321">999 999 99 99</a>
                    </h4>
                    <span>Call Now</span>
                  </div>
                </div>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Our Policies</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/contacts">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Editorial Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">IP Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Grievance Redressal Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Our Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Our Services</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/toylist">Our Products</Link>
                    </li>
                    <li>
                      <Link to="/trending">Trending</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Editorial Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Grievance Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Redressal Policy</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Instagram</h4>
                <div className="fw-insta">
                  <ul>
                    <li>
                      <a href="/#">
                        <img src="img/images/image1x1.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/image2x1.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/image3x1.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/image1x2.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/image2x2.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/image3x2.jpg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img src="img/images/footer_shape01.png" alt="" />
        </div>
        <div className="footer-shape shape-two">
          <img src="img/images/footer_shape02.png" alt="" />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>Copyright © 2022 OMSToys. All Rights Reserved.</p>
              </div>
            </div>
            <div className="col-md-4 d-none d-md-block">
              <div className="footer-lang">
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="img/icon/united-states.png" alt="" /> English
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <Link className="dropdown-item" to="/">
                      <img src="img/icon/russia.png" alt="" />
                      Russia
                    </Link>
                    <Link className="dropdown-item" to="/">
                      <img src="img/icon//thailand.png" alt="" />
                      Thailand
                    </Link>
                    <Link className="dropdown-item" to="/">
                      <img src="img/icon/india.png" alt="" />
                      India
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
