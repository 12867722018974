import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchProductData, listCetegory } from "../../utils/apis";

function ShopArea() {
  const [activeButton, setActiveButton] = useState(null);
  const [priceFilter, setPriceFilter] = useState([]);
  const [cat_id, setCatId] = useState();

  const handlePriceFilter = async (min, max) => {
    setPriceFilter([min, max]);
    setActiveButton(`${min}-${max}`);
    setCatId("");
    await refetch();
  };

  const handleAllProducts = async () => {
    setPriceFilter([]);
    setActiveButton(null);
    setCatId("");
    await refetch();
  };

  const requestBody = priceFilter.length > 0 ? { price: priceFilter } : {};

  const {
    refetch,
    isPending: productIsPending,
    isError: productIsError,
    data: productData,
    error: productError,
  } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProductData(requestBody, cat_id),
  });

  const {
    isPending: categoryIsPending,
    isError: categoryIsError,
    data: categoryData,
    error: categoryError,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: listCetegory,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch({ price: priceFilter, category_id: cat_id });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [priceFilter, refetch, cat_id]);

  const renderLoader = () => (
    <div className="text-center">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );

  const renderError = (error) => <span>Error: {error?.message}</span>;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showCategoryList, setShowCategoryList] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);

      // If switching from mobile to desktop, automatically hide the mobile sidebar
      if (!newIsMobile) {
        setShowCategoryList(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCategoryList = () => {
    setShowCategoryList(!showCategoryList);
  };

  return (
    <div className="shop-area pt-50 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          {isMobile && (
            <div className="shop-cat-list">
              <a
                onClick={toggleCategoryList}
                className="btn btn-sm"
                style={{
                  padding: "0.25rem 0.5rem",
                  fontSize: "0.875rem",
                  lineHeight: "1.5",
                  borderRadius: "0.2rem",
                  marginBottom: "20px",
                }}
              >
                {showCategoryList ? "Hide Filters" : "Show Filters"}
              </a>
            </div>
          )}

          {showCategoryList && (
            <div className="col-lg-3 col-md-8 order-0 order-lg-0">
              <aside className="shop-sidebar">
                <div className="widget"></div>
                <div className="widget">
                  <h4 className="sidebar-title">Category</h4>
                  <div className="shop-cat-list">
                    {categoryIsPending && renderLoader()}
                    {categoryIsError ? renderError() : null}
                    {!categoryIsPending && !categoryIsError && categoryData && (
                      <ul>
                        {categoryData?.map((category) => (
                          <li key={category.id}>
                            <a
                              onClick={() => setCatId(category.id)}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {category.name} <span>+</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="widget" style={{ marginBottom: "25px" }}>
                  <h4 className="sidebar-title">Filter by Price</h4>
                  <div className="shop-cat-list">
                    <ul>
                      <li>
                        <a
                          onClick={handleAllProducts}
                          style={{
                            backgroundColor:
                              activeButton === null ? "#your-active-color" : "",
                            color: activeButton === null ? "red" : "",
                            cursor: "pointer",
                          }}
                        >
                          All Products
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handlePriceFilter(100, 500)}
                          style={{
                            backgroundColor:
                              activeButton === "100-500"
                                ? "#your-active-color"
                                : "",
                            color: activeButton === "100-500" ? "red" : "",
                            cursor: "pointer",
                          }}
                        >
                          ₹100 - ₹500
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handlePriceFilter(500, 1500)}
                          style={{
                            backgroundColor:
                              activeButton === "500-1500"
                                ? "#your-active-color"
                                : "",
                            color: activeButton === "500-1500" ? "red" : "",
                            cursor: "pointer",
                          }}
                        >
                          ₹500 - ₹1500
                        </a>
                      </li>
                      {/* Repeat similar button blocks for other price ranges */}
                      {/* ... */}
                      <li>
                        <a
                          onClick={() => handlePriceFilter(1500)}
                          style={{
                            backgroundColor:
                              activeButton === "1500" ? "red" : "",
                            color: activeButton === "1500" ? "red" : "",
                            cursor: "pointer",
                          }}
                        >
                          ₹1500 - More
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {!isMobile && (
                  <div className="widget shop-widget-banner">
                    <Link to="/shop">
                      <img src="img/product/shop_add.jpg" alt="" />
                    </Link>
                  </div>
                )}
              </aside>
            </div>
          )}

          <div className="col-lg-9">
            <div className="shop-wrap">
              <h4 className="title">Shop</h4>
              {productIsPending && renderLoader()}
              {productError ? renderError() : null}
              {!productIsPending && !productError && productData && (
                <div className="row justify-content-center">
                  {productData.map((product) => (
                    <div
                      key={product.id}
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"
                    >
                      <div className="shop-item mb-55">
                        <div className="shop-thumb">
                          <Link to={`/shop-details/${product.id}`}>
                            <img src={product.image} alt="" />
                          </Link>
                        </div>
                        <div className="shop-content">
                          <span>{product.category}</span>
                          <h4 className="title">
                            <Link to={`/shop-details/${product.id}`}>
                              {product.title}
                            </Link>
                          </h4>
                          <div className="shop-content-bottom">
                            <span className="price">{product.price}</span>
                            <span className="add-cart">
                              <Link to={`/shop-details/${product.id}`}>
                                BUY NOW +
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* <div className="shop-page-meta">
              
                <div className="shop-showing-result">
                  <p>Total Items 1-12 of 13</p>
                </div>
                <div className="shop-show-list">
                  <form>
                    <label htmlFor="bottomShow">Show</label>
                    <select id="bottomShow" className="selected">
                      <option value>08</option>
                      <option value>12</option>
                      <option value>16</option>
                      <option value>18</option>
                      <option value>20</option>
                    </select>
                  </form>
                </div>
                <div className="shop-pagination">
                  <ul>
                    <li className="active">
                      <Link to="/shop">1</Link>
                    </li>
                    <li>
                      <Link to="/shop">2</Link>
                    </li>
                    <li>
                      <Link to="/shop">
                        <i className="fas fa-angle-double-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopArea;
